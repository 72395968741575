import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'

// Components
import ArrowLink from 'components/elements/ArrowLink'

// Helpers
import { getLanguage } from 'services/language'

const ServiceDetailMenu = () => {
  const language = getLanguage()
  const {
    itemsNL,
    itemsEN
  } = useStaticQuery(graphql`
    query {
      itemsNL: allWpPage(filter: {wpParent: {node: {databaseId: {eq: 151}}}}) {
        nodes {
          title
          uri
        }
      }

      itemsEN: allWpPage(filter: { wpParent: {node: {databaseId: {eq: 608}}}}) {
        nodes {
          title
          uri
        }
      }
    }
  `)

  const items = language === 'nl_NL' ? itemsNL.nodes : itemsEN.nodes

  return (
    <div className="row">
      {items.map(item => (
        <div className="col-lg-6" key={item.key}>
          <ArrowLink className="my-3 font-size-m" to={item.path}>
            {item.title}
          </ArrowLink>
        </div>
      ))}
    </div>
  )
}

export default ServiceDetailMenu
