/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import { graphql } from 'gatsby'

// Components
import SEO from 'components/SEO'
import Layout from 'components/shared/Layout'
import ParseContent from 'components/shared/ParseContent'
import Hero from 'components/elements/Hero'
import ProjectsSection from 'components/shared/ProjectsSection'
import KoelMonteurs from 'components/shared/before-footer/Koelmonteurs'
import BetonkoelingSection from 'components/specialties/Betonkoeling'
import Brochure from 'components/sidebar/Brochure'
import OnzeWerkwijze from '../components/OnzeWerkwijze'

// Third Party
import styled from 'styled-components'

export const pageQuery = graphql`
  query ($id: String) {
    page: wpPage(id: { eq: $id }) {
      id
      title
      uri
      content
      locale {
        locale
      }
      templateHeadSpecs {
        fieldGroupName
        headspecbanner {
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 2000, quality: 100)
                meta: fluid(maxWidth: 800, toFormat: JPG) {
                  src
                }
              }
            }
          }
          title
        }

        headspecintro {
          title
          description
        }

        headspecbrochure {
          title
          contactbutton {
            title
            url
          }
          download {
            title
            file {
              localFile {
                publicURL
              }
            }
          }
        }
        component {
          ... on WpComponent {
            databaseId
          }
        }
      }
      pageBeton {
        fieldGroupName
        betonkoeling {
          title
        }
      }
      seo {
        canonical
        cornerstone
        focuskw
        metaDesc
        metaKeywords
        metaRobotsNofollow
        metaRobotsNoindex
        opengraphAuthor
        opengraphDescription
        opengraphModifiedTime
        opengraphPublisher
        opengraphPublishedTime
        opengraphSiteName
        opengraphTitle
        opengraphType
        opengraphUrl
        title
        twitterDescription
        twitterTitle
        opengraphImage {
          sourceUrl
        }
        twitterImage {
          sourceUrl
        }
      }
    }
  }
`

const TitleContainer = styled.div`
  br {
    display: none;
  }
`

const PageTemplate = ({
  data: {
    page: { locale, seo, templateHeadSpecs, pageBeton },
  },
}) => {
  return (
    <Layout>
      {seo && (
        <SEO
          seo={seo}
          socialImage={
            templateHeadSpecs.headspecbanner.image.localFile.childImageSharp
              .meta.src
          }
        />
      )}
      <Hero
        src={templateHeadSpecs.headspecbanner.image}
        title={templateHeadSpecs.headspecbanner.title}
      />
      <section className="container py-5">
        <div className="row">
          <div className="col-lg-6">
            <div className="col-lg-12 pl-0">
              <TitleContainer className="ml-lg-n5 mb-5">
                <ParseContent content={templateHeadSpecs.headspecintro.title} />
              </TitleContainer>
            </div>
            <div>
              <ParseContent
                content={templateHeadSpecs.headspecintro.description}
              />
            </div>
          </div>

          <Brochure brochure={templateHeadSpecs.headspecbrochure} />
        </div>
      </section>
      {/* Betonkoeling */}
      {pageBeton.betonkoeling && pageBeton.betonkoeling.title && (
        <>
          <BetonkoelingSection title={pageBeton.betonkoeling.title} />
          {/* <ProjectsSection /> */}
        </>
      )}

      <OnzeWerkwijze id={locale.locale === 'nl_NL' ? 2510 : 2542} />

      <KoelMonteurs className="mt-lg-5 pt-lg-5" />
    </Layout>
  )
}

export default PageTemplate
