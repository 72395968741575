import React from 'react'
import ServiceDetailMenu from 'components/ServiceDetailMenu'

// Third Party
import styled from 'styled-components'

const EndlessContainer = styled.div`
  background: ${(props) => props.theme.color.alt};
  position: relative;
  
  &::after {
    content: "";
    display:block;
    width: 150%;
    height: 100%;
    background-color: ${(props) => props.theme.color.alt};
    position: absolute;
    left: 100%;
    top: 0;
  }
`;

const BetonkoelingSection = ({ title }) => {
  return (
    <section className="container mb-5">
      <EndlessContainer>
        <div className="row align-items-center p-5">
          <div className="col-lg-6">
            <h2 className="line-height-m pr-3 mb-0">{title}</h2>
          </div>
          <div className="col-lg-6">
            <ServiceDetailMenu />
          </div>
        </div>
      </EndlessContainer>
    </section>
  )
}

export default BetonkoelingSection
